import * as React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import { Col, Row, Container } from "react-bootstrap"
import FadeIn from "../components/fade-in"


const Cookies = ({ data }) => {
  return (
    <Layout>
      <Seo
        title="Cookies - Signaclass"
        description="Our use of cookies on this site."
      />
      <FadeIn delay={200} duration={1500}>
        <Container>
          <Row>
            <Col sm="12" className="mt-5 pt-5 mb-3 text-center">
              <h1>
                <strong>Cookie Policy</strong>
              </h1>
            </Col>
          </Row>
          <Row>
            <Col>
              <div className="shadow p-4 white">
                <p>
                  We don't use many cookies on our website because we believe in
                  privacy and your right to choose how companies use your data.
                  That said, cookies are a vital part of the internet. Cookies
                  enable us to do some essential things like store your login
                  session or understand whether we need to increase resources in
                  our infrastructure. So, we use this policy to inform you of
                  our cookie use.
                </p>
                <h2>Necessary Cookies</h2>
                <p>
                  Some cookies are necessary for maintaining security and access
                  to computer systems. They manage things that would break
                  without accepting them.
                </p>
                <h4>Necessary Cookies set by Signaclass:</h4>
                <p>
                  We set some essential cookies to manage your login and retain
                  essential preferences with respect to other cookies.{" "}
                  <a href="/privacy-policy">
                    You can read our privacy policy here.
                  </a>
                </p>
                <ul>
                  <li>
                    rs_cu: this cookie manages authentication and your login
                    session - stored for up to 24 hours.
                  </li>
                  <li>
                    We set a localStorage flag to determine your preferences for
                    our live chat widget.
                  </li>
                </ul>
                <h4>Necessary Cookies set by Stripe:</h4>
                <p>
                  Stripe, our payment processor, sets some cookies for fraud
                  prevention and facilitates payments. Stripe will only send
                  these cookies if you access our payment pages.{" "}
                  <a href="https://stripe.com/ie/privacy">
                    You can read their privacy policy here.
                  </a>
                </p>
                <ul>
                  <li>
                    _stripe_mid: Stripe might set this cookie to prevent fraud,
                    detect bots and guarantee the security of payments. It
                    expires after one year.
                  </li>
                  <li>
                    __stripe_sid: Stripe might set this cookie to prevent fraud,
                    detect bots and guarantee the security of payments. It
                    expires after 30 minutes.
                  </li>
                  <li>
                    m: Stripe might set this cookie during payments to ensure
                    their integrity and protect you from fraud. It expires after
                    two years.
                  </li>
                </ul>
                <h4>Necessary Cookies set by Cloudflare:</h4>
                <p>
                  We use Cloudflare as a traffic optimization and distribution
                  service - it helps speed up our website, keep our
                  infrastructure costs down and protect us from security
                  threats, including bots. They set up some cookies that are
                  strictly necessary to protect our infrastructure.{" "}
                  <a href="https://developers.cloudflare.com/fundamentals/get-started/reference/cloudflare-cookies/">
                    You can read about their cookies here.
                  </a>
                </p>
                <ul>
                  <li>
                    __cf_bm: Cloudflare might set this cookie if we trigger or
                    you activate Cloudflare's bot protection. It allows
                    Cloudflare to identify that you are not a bot and allow you
                    access to our website. It expires after 30 minutes.
                  </li>
                  <li>
                    cf_ob_info: This cookie provides information on HTTP status
                    codes and data centre routes taken to our website. It
                    expires after 30 seconds.
                  </li>
                  <li>
                    cf_use_ob: This cookie provides information on HTTP status
                    codes and data centre routes taken to our website. It
                    expires after 30 seconds.
                  </li>
                </ul>
                <h2>Scope</h2>
                <p>
                  This cookie policy only refers to the "top-level domain"
                  signaclass.com. Not to its subdomains operated on behalf of
                  data controllers we process for - in that case, you should
                  consult that data controller's privacy policies.
                </p>
                <em>This cookie policy was last updated 29th May 2022</em>
              </div>
            </Col>
          </Row>
        </Container>
      </FadeIn>
    </Layout>
  )
}

export default Cookies
